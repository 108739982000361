<header>
    <p-messages [(value)]="_msgs"></p-messages>
    <h2 class="clearfix">
        Non-Disclosure Extensions
        <span class="pull-right">
            <p-button icon="pi pi-home" styleClass="ui-button-success" label="My Requests" (click)="showDashboard()" queryParamsHandling="preserve" pTooltip="Click to go back to My Requests Dashboard" tooltipPosition="bottom"></p-button>&nbsp;&nbsp;
            <p-button icon="pi pi-search" label="Search/Create NDO Extension" queryParamsHandling="preserve" (click)="searchDialogOpen()" pTooltip="Click to Search/Create NDO Extension" tooltipPosition="bottom"></p-button>
        </span>
    </h2>
</header>
<div id="divNDOAlert" class="content-ndoAlert">
    <strong>Attention!</strong><br /><br />
    <small>
        Are you submitting an order extending a nondisclosure order (NDO) associated with previously submitted legal process?<br /><br />
        This “NDO Extension” feature is solely for the submission of NDO extension orders.  Any other type of legal process, such as new legal demands or preservation requests, will not be processed. To submit a new request, please return to My Requests dashboard and select the “+New Request” button.
    </small>
</div>
<ndo-my-submissions [_resetDataGrid]="_resetDataGrid">
</ndo-my-submissions>
<form [formGroup]="_frmNDOSearch">
    <p-dialog title="Search/Create NDO Extension" class="content-search-modal" styleClass="content-search-modal" position="top" [transitionOptions]="'0ms'" [visible]="_showSearchDialog$ | async" [closable]="false" [draggable]="false" modal="true">
        <p-header class="title">
            Search/Create NDO Extension - {{_title}}
        </p-header>
        <p-steps [(activeIndex)]="_activeIndex" [model]="_steps">
        </p-steps>
        <div class="content">
            <div *ngIf="_activeIndex == 0">
                <div class="container">
                    <div class="row">
                        <div class="col">
                        </div>
                        <div class="col-10">
                            <br /> <br />Important note regarding extensions of non-disclosure orders: In order to process an extension of a non-disclosure order, Microsoft requires that you reference the LNS number(s) (formally known as a GCC number) associated with the underlying legal demand to which the extension of the non-disclosure order applies (i.e., the original subpoena, court order, warrant, or other legal process). Due to the variety of internal tracking and reference numbers used by law enforcement agencies and courts across the country, including the LNS number(s) is necessary to ensure that Microsoft can appropriately and consistently track extensions of non-disclosure orders.
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_activeIndex == 1">
                <br />
                <div class="container">
                    <div class="row">
                        <div class="col">
                        </div>
                        <div class="col-4">
                            <br />
                            <table cellspacing="0" cellpadding="0" border="0">
                                <tr>
                                    <th colspan="2">MS Reference Number:</th>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="ui-inputgroup">
                                            <span class="ui-inputgroup-addon">
                                                <i class="pi pi-table" style="line-height: 1.25;"></i>
                                            </span>
                                            <p-inputMask pTooltip="Enter the Request LNS or MS Reference Number" tooltipPosition="bottom" formControlName="_frmNDOSearchleLensNumber" id="_frmNDOSearchleLensNumber" required="true" mask="aaa-999999-******" placeholder="LNS-XXXXXX-XXXXXX" class="ng-invalid ng-dirty"></p-inputMask>
                                        </div>
                                    </td>
                                    <td>
                                        <p-button (click)="searchById()" id="_btnSearch" styleClass="ui-button-primary" label="Search" pTooltip="Click here to Search" tooltipPosition="bottom"></p-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col"></div>
                        <div class="col-10">
                            <br />
                            <p-dialog autoZIndex="true" header="MS Reference Number Request was not found!" [visible]="_showSearchNotFoundDialog$ | async" [closable]="false" [draggable]="false" model="true">
                                <div style="text-align:center;">
                                    <span class="content-label">{{getfrmNDOSearchleLensNumber().value}}</span>
                                </div>
                                <br /><br />
                                <div class="pull-right">
                                    <p-button styleClass="ui-button-secondary" label="Ok" (click)="searchNotFoundDialogClose()" pTooltip="Ok" tooltipPosition="bottom"></p-button>
                                </div>
                            </p-dialog>
                            <p-dialog autoZIndex="true" header="This Request is not supported for NDO Extensions!" [visible]="_showInternationalNotAllowedDialog$ | async" [closable]="false" [draggable]="false" model="true">
                                <div style="text-align:center;">
                                    <span class="content-label">{{getfrmNDOSearchleLensNumber().value}}</span>
                                </div>
                                <br /><br />
                                <div class="pull-right">
                                    <p-button styleClass="ui-button-secondary" label="Ok" (click)="internatonalNotAllowedDialogClose()" pTooltip="Ok" tooltipPosition="bottom"></p-button>
                                </div>
                            </p-dialog>
                            <p-table #dtSearch [value]="_searchLNSList" [loading]="_loading$ | async" [responsive]="true" class="content-modal-search" autoLayout="true" ptable508 formControlName="_frmNDOSearchTable">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th class="content-search-result-column-45" [pSortableColumn]="'TicketNumber'">MS Reference Number<p-sortIcon [field]="'TicketNumber'"></p-sortIcon></th>
                                        <th class="content-search-result-column-45" [pSortableColumn]="'OriginalReceivedDate'">Submitted Date<p-sortIcon [field]="'OriginalReceivedDate'"></p-sortIcon></th>
                                        <th class="content-search-result-column-45" [pSortableColumn]="'ExpirationDate'">Expiration Date<p-sortIcon [field]="'ExpirationDate'"></p-sortIcon></th>
                                        <th class="content-search-result-column-10"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-row>
                                    <tr>
                                        <td class="ui-column-title">{{row.TicketNumber}}</td>
                                        <td class="ui-column-title">{{row.OriginalReceivedDate | date:'yyyy-MM-dd'}}</td>
                                        <td class="ui-column-title">{{row.ExpirationDate | date:'yyyy-MM-dd'}}</td>
                                        <td>
                                            <button pButton pTooltip="Click here to remove {{row.TicketNumber}}" label="Remove" tooltipPosition="bottom" class="ui-button-rounded ui-button-danger" icon="pi pi-times-circle" (click)="searchRowDelete(row)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td [attr.colspan]="5">
                                            <i>Please Search for the MS Reference Numbers to add an NDO Extension for.</i>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div class="col"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="_activeIndex == 2">
                <br />
                <div class="container">
                    <div class="row">
                        <div class="col">
                        </div>
                        <div class="col-md-auto">
                            <br /><br />
                            <table cellpadding="1">
                                <tr>
                                    <td colspan="2" style="padding:15px;"><small>Please enter the new expiration date set forth in the NDO extension order.</small></td>
                                </tr>
                                <tr>
                                    <td>Expiration Date:</td>
                                    <td><p-calendar [showTime]="false" showIcon="true" formControlName="_frmNDOExpirationDate" required="true" [minDate]="_minDate"></p-calendar></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_activeIndex == 3">
                <div class="container">
                    <div class="row">
                        <div class="col">
                        </div>
                        <div class="col-md-9">
                            <br /><br />
                            <div class="ui-inputgroup">
                                <span class="ui-inputgroup-addon">
                                    Document Name:<i class="pi" style="line-height: 1.25;"></i>
                                </span>
                                <input type="text" pTooltip="Enter a Document Name" tooltipPosition="bottom" formControlName="_frmNDOFileName" id="_frmNDOFileName" placeholder="Document Name" class="ng-invalid ng-dirty" />
                            </div>
                            <br />
                            <div class="ndo-confirm">
                                <file-upload [form]="_frmNDOSearch" formcontrolname="_frmNDOFile" required="true"> </file-upload>
                                <small>
                                    <p-checkbox formControlName="_isNdoConfirm"
                                                binary="true"
                                                ariaLabelledBy="_isNdoConfirm"
                                                label="I have uploaded a Non-Disclosure Order that complies with 18 U.S.C. § 2705(b) and/or the legal process I have uploaded clearly identifies applicable federal/state law that may restrict notification to the user.">
                                    </p-checkbox>
                                </small>
                            </div>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="_activeIndex == 4">
                <br />
                <div class="container">
                    <div class="row">
                        <div class="col">
                        </div>
                        <div class="col-md-9">
                            <div class="p-col">
                                <p-message severity="warn"
                                           text="Please note that Microsoft gives prior notice to users and customers whose data is sought by a law enforcement agency or other governmental entity, except where prohibited by a valid and applicable nondisclosure order. Microsoft also provides delayed notice to users upon expiration of a valid and applicable nondisclosure order unless Microsoft, in its sole discretion, believes that providing notice could result in danger to identifiable individuals or groups or be counterproductive.">
                                </p-message>
                            </div>
                            <div class="p-col ndo-confirm">
                                <p-checkbox formControlName="_isNDOUploadFinalConfirm"
                                            binary="true"
                                            label="I have reviewed the uploaded file(s) and confirm that I have submitted a Non-Disclosure Order that complies with 18 U.S.C. § 2705(b) and/or the legal process clearly identifies applicable federal/state law that may restrict notification to the user.">
                                </p-checkbox>
                                <hr />
                            </div>
                            <div class="p-col">
                                <div style="background-color: lightgray; border: 1px solid black; padding: 1% 1% 1% 1%">
                                    <p>
                                        I acknowledge that if I have not uploaded a legally valid
                                        Non-Disclosure Order or clearly referenced applicable federal/state
                                        law as part of this submission, Microsoft will, in its discretion and
                                        consistent with Microsoft’s obligations to its users and customers,
                                        take steps to notify the user or customer of this law enforcement
                                        request for information or will reject this request for discrepancies
                                        in the submission process.
                                    </p>
                                    <p>
                                        Please type "I acknowledge" in the box below to acknowledge your NDO
                                        decision.&nbsp;<span style="color: red">*</span>
                                    </p>
                                    <span class="ui-label mt-3">
                                        <input type="text"
                                               size="50"
                                               formControlName="_acknowledgement"
                                               autocomplete="off"
                                               pInputText />
                                    </span>
                                    <ng-container *ngIf="_notSame">
                                        <div>
                                            <span class="ui-messages-error" style="color: red;">Does not match (case sensitive): I acknowledge</span>
                                        </div>
                                    </ng-container>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div class="col">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-1">
                        <p-button *ngIf="_activeIndex > 0" (click)="stepPrevious()" pTooltip="Previous" label="Previous" tooltipPosition="bottom" icon="pi pi-arrow-circle-left" iconPos="left"></p-button>
                    </div>
                    <div class="col-10">
                        <div style="align-content:center;text-align:center;">
                            <button pTooltip="Click here to Close" (click)="searchDialogClose()" tooltipPosition="bottom" type="button" icon="pi pi-times-circle" pButton class="ui-button-secondary" label="Cancel/Close"></button>
                        </div>
                    </div>
                    <div class="col-1">
                        <div *ngIf="_activeIndex < 4">
                            <p-button (click)="stepNext()" pTooltip="Next" label="Next" tooltipPosition="bottom" icon="pi pi-arrow-circle-right" iconPos="right"></p-button>
                        </div>
                        <div *ngIf="_activeIndex == 4">
                            <p-button (click)="stepNext()" pTooltip="Submit" label="Submit" tooltipPosition="bottom" icon="pi pi-save" iconPos="right"></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </p-footer>
    </p-dialog>
    <p-dialog title="Required Information" position="middle" [transitionOptions]="'100ms'" [visible]="_showValidationDialog$ | async" [closable]="false" [draggable]="false" modal="true" class="content-validation-modal">
        <p-header>
            Required Information
        </p-header>
        <br />
        <div *ngFor="let item of _validationErrors; let i = index">
            <div>{{item}}<br /><br /></div>
            <div *ngIf="i>0 && i<_validationErrors.length">
            </div>
        </div>
        <p-footer>
            <div style="text-align:center;">
                <button pTooltip="Click here to Close" (click)="validationDialogClose()" tooltipPosition="bottom" type="button" icon="pi pi-times-circle" pButton class="ui-button-primary" label="Ok"></button>
            </div>
        </p-footer>
    </p-dialog>
    <p-confirmDialog header="Confirmation">
    </p-confirmDialog>
</form>
